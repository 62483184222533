body {
  margin: 0;
}

.custom {
  /* General styles can go here if needed */
}

/* Specific styles based on element type */
.custom h1 {
  font-family: "Red Hat Display", sans-serif;
  font-size: 4rem;
  font-weight: bold;
}

.custom h2 {
  font-family: "Red Hat Display", sans-serif;
  font-size: 3.5rem;
  font-weight: bold;
}

.custom h3 {
  font-family: "Red Hat Display", sans-serif;
  font-size: 3rem;
  font-weight: bold;
}

.custom h4 {
  font-family: "Red Hat Display", sans-serif;
  font-size: 2.5rem;
  font-weight: bold;
}

.custom h5 {
  font-family: "Red Hat Display", sans-serif;
  font-size: 2rem;
  font-weight: bold;
}

.custom h6 {
  font-family: "Red Hat Display", sans-serif;
  font-size: 1.5rem;
  font-weight: bold;
}

.custom p {
  font-family: "Open Sans", sans-serif;
  font-size: 1rem;
  font-weight: 400;
}

.custom strong {
  font-weight: bold;
}
